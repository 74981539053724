<template>
  <sims-layout>
    <div class="sims-chats">
      <!-- All Chats -->
      <div class="card">
        <div class="header">
          <div class="flex-wrapper">
            <div class="left">
              <h3>Messaging</h3>
            </div>
            <div class="right">
              <!-- <a-button @click.prevent="$router.push('/sims/call-for-help')" type="primary">Call For Help</a-button> -->
            </div>
          </div>
        </div>
        <div class="body bb" v-if="!isLoading">
          <!-- Search -->
          <div class="search">
            <a-input-search
              style="max-width: 300px"
              placeholder="Search chats"
              v-model="search_query"
            />
          </div>

          <!-- / Search -->
        </div>
        <div class="body">
          <!-- Loading -->
          <div class="loader" v-if="isLoadingCombined">
            <a-spin></a-spin>
          </div>
          <!-- / Loading -->

          <!-- No chats -->
          <a-alert
            v-if="!isLoadingCombined && chatsToShow.length == 0"
            type="info"
            message="No chats to show"
          />
          <!-- / No chats -->

          <!-- List -->
          <div class="list" v-if="!isLoadingCombined">
            <chat-list-item
              v-for="(chat, i) in chatsToShowOrdered"
              :key="i"
              :chat="chat"
            ></chat-list-item>
          </div>
          <!-- / List -->
        </div>
      </div>
      <!-- / All Chats -->
    </div>
  </sims-layout>
</template>

<script>
import simsLayout from "./simsLayout.vue";
import ChatListItem from "../../components/sims/Chats/ChatListItem.vue";
import { mapGetters, mapActions } from "vuex";
import Chats from "../../mixins/Chats";
import Dates from "../../mixins/Dates";
import organisations from "../../helpers/organisations";
const _ = require("lodash");

export default {
  components: { simsLayout, ChatListItem },
  mixins: [Chats, Dates],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
      isLoadingMissionControl: "isLoading",
    }),

    ...mapGetters("chats", {
      chatsToShow: "chatsToShow",
      chatsToShowOrdered: "chatsToShowOrdered",
      isLoading: "isLoading",
      searchQuery: "searchQuery",
    }),

    search_query: {
      set(val) {
        this.setSearchQuery(val);
      },
      get() {
        return this.searchQuery;
      },
    },

    isLoadingCombined() {
      return this.isLoading || this.isLoadingMissionControl;
    },
  },
  methods: {
    ...mapActions("missionControl", {
      getOrganisationById: "getOrganisationById",
    }),

    ...mapActions("chats", {
      loadChats: "loadChats",
      setSearchQuery: "setSearchQuery",
    }),

    getOrganisationById(id) {
      return _.find(this.organisations, { id });
    },

    getChatUrl(chat) {
      let organisation = this.getOrganisationById(chat.ownerId);
      let tenantId = organisations.getOrganisationTenantId(organisation);
      return "/sims/chats/" + tenantId + "/" + chat.id;
    },
  },
};
</script>

<style scoped lang="scss">
.create-button {
  margin-top: 20px;
}

.flex-wrapper {
  display: flex;
  width: 100%;

  .left {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }
}
</style>